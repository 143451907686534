var dbits;var count1=0;var canary=0xdeadbeefcafe;var j_lm=(canary&16777215)==15715070;function e2ee_i(a,b,c){if(a!=null)if("number"==typeof a)this.e2ee_br(a,b,c);else if(b==null&&"string"!=typeof a)this.e2ee_K(a,256);else this.e2ee_K(a,b)}function nbi(){return new e2ee_i(null)}function am1(i,x,w,j,c,n){while(--n>=0){var v=x*this[i++]+w[j]+c;c=Math.floor(v/67108864);w[j++]=v&67108863}return c}
function am2(i,x,w,j,c,n){var xl=x&32767,xh=x>>15;while(--n>=0){var l=this[i]&32767;var h=this[i++]>>15;var m=xh*l+h*xl;l=xl*l+((m&32767)<<15)+w[j]+(c&1073741823);c=(l>>>30)+(m>>>15)+xh*h+(c>>>30);w[j++]=l&1073741823}count1++;return c}function am3(i,x,w,j,c,n){var xl=x&16383,xh=x>>14;while(--n>=0){var l=this[i]&16383;var h=this[i++]>>14;var m=xh*l+h*xl;l=xl*l+((m&16383)<<14)+w[j]+c;c=(l>>28)+(m>>14)+xh*h;w[j++]=l&268435455}return c}
if(j_lm&&navigator.appName=="Microsoft Internet Explorer"){e2ee_i.prototype.am=am2;dbits=30}else if(j_lm&&navigator.appName!="Netscape"){e2ee_i.prototype.am=am1;dbits=26}else{e2ee_i.prototype.am=am3;dbits=28}e2ee_i.prototype.DB=dbits;e2ee_i.prototype.DM=(1<<dbits)-1;e2ee_i.prototype.DV=1<<dbits;var BI_FP=52;e2ee_i.prototype.FV=Math.pow(2,BI_FP);e2ee_i.prototype.F1=BI_FP-dbits;e2ee_i.prototype.F2=2*dbits-BI_FP;var BI_RM="0123456789abcdefghijklmnopqrstuvwxyz";var BI_RC=new Array;var rr,vv;rr="0".charCodeAt(0);
for(vv=0;vv<=9;++vv)BI_RC[rr++]=vv;rr="a".charCodeAt(0);for(vv=10;vv<36;++vv)BI_RC[rr++]=vv;rr="A".charCodeAt(0);for(vv=10;vv<36;++vv)BI_RC[rr++]=vv;function e2ee_L(n){return BI_RM.charAt(n)}function intAt(s,i){var c=BI_RC[s.charCodeAt(i)];return c==null?-1:c}function e2ee_ai(r){for(var i=this.t-1;i>=0;--i)r[i]=this[i];r.t=this.t;r.s=this.s}function e2ee_aC(x){this.t=1;this.s=x<0?-1:0;if(x>0)this[0]=x;else if(x<-1)this[0]=x+DV;else this.t=0}function nbv(i){var r=nbi();r.e2ee_N(i);return r}
function e2ee_ad(s,b){var k;if(b==16)k=4;else if(b==8)k=3;else if(b==256)k=8;else if(b==2)k=1;else if(b==32)k=5;else if(b==4)k=2;else{this.e2ee_bv(s,b);return}this.t=0;this.s=0;var i=s.length,mi=false,sh=0;while(--i>=0){var x=k==8?s[i]&255:intAt(s,i);if(x<0){if(s.charAt(i)=="-")mi=true;continue}mi=false;if(sh==0)this[this.t++]=x;else if(sh+k>this.DB){this[this.t-1]|=(x&(1<<this.DB-sh)-1)<<sh;this[this.t++]=x>>this.DB-sh}else this[this.t-1]|=x<<sh;sh+=k;if(sh>=this.DB)sh-=this.DB}if(k==8&&(s[0]&128)!=
    0){this.s=-1;if(sh>0)this[this.t-1]|=(1<<this.DB-sh)-1<<sh}this.clamp();if(mi)e2ee_i.ZERO.subTo(this,this)}function e2ee_aK(){var c=this.s&this.DM;while(this.t>0&&this[this.t-1]==c)--this.t}
function e2ee_aI(b){if(this.s<0)return"-"+this.e2ee_P().toString(b);var k;if(b==16)k=4;else if(b==8)k=3;else if(b==2)k=1;else if(b==32)k=5;else if(b==4)k=2;else return this.e2ee_bS(b);var km=(1<<k)-1,d,m=false,r="",i=this.t;var p=this.DB-i*this.DB%k;if(i-- >0){if(p<this.DB&&(d=this[i]>>p)>0){m=true;r=e2ee_L(d)}while(i>=0){if(p<k){d=(this[i]&(1<<p)-1)<<k-p;d|=this[--i]>>(p+=this.DB-k)}else{d=this[i]>>(p-=k)&km;if(p<=0){p+=this.DB;--i}}if(d>0)m=true;if(m)r+=e2ee_L(d)}}return m?r:"0"}
function e2ee_bk(){var r=nbi();e2ee_i.ZERO.subTo(this,r);return r}function bnAbs(){return this.s<0?this.e2ee_P():this}function e2ee_aq(a){var r=this.s-a.s;if(r!=0)return r;var i=this.t;r=i-a.t;if(r!=0)return r;while(--i>=0)if((r=this[i]-a[i])!=0)return r;return 0}function nbits(x){var r=1,t;if((t=x>>>16)!=0){x=t;r+=16}if((t=x>>8)!=0){x=t;r+=8}if((t=x>>4)!=0){x=t;r+=4}if((t=x>>2)!=0){x=t;r+=2}if((t=x>>1)!=0){x=t;r+=1}return r}
function e2ee_bc(){if(this.t<=0)return 0;return this.DB*(this.t-1)+nbits(this[this.t-1]^this.s&this.DM)}function e2ee_av(n,r){var i;for(i=this.t-1;i>=0;--i)r[i+n]=this[i];for(i=n-1;i>=0;--i)r[i]=0;r.t=this.t+n;r.s=this.s}function e2ee_bN(n,r){for(var i=n;i<this.t;++i)r[i-n]=this[i];r.t=Math.max(this.t-n,0);r.s=this.s}
function e2ee_aO(n,r){var bs=n%this.DB;var cbs=this.DB-bs;var bm=(1<<cbs)-1;var ds=Math.floor(n/this.DB),c=this.s<<bs&this.DM,i;for(i=this.t-1;i>=0;--i){r[i+ds+1]=this[i]>>cbs|c;c=(this[i]&bm)<<bs}for(i=ds-1;i>=0;--i)r[i]=0;r[ds]=c;r.t=this.t+ds+1;r.s=this.s;r.clamp()}
function e2ee_aV(n,r){r.s=this.s;var ds=Math.floor(n/this.DB);if(ds>=this.t){r.t=0;return}var bs=n%this.DB;var cbs=this.DB-bs;var bm=(1<<bs)-1;r[0]=this[ds]>>bs;for(var i=ds+1;i<this.t;++i){r[i-ds-1]|=(this[i]&bm)<<cbs;r[i-ds]=this[i]>>bs}if(bs>0)r[this.t-ds-1]|=(this.s&bm)<<cbs;r.t=this.t-ds;r.clamp()}
function e2ee_bR(a,r){var i=0,c=0,m=Math.min(a.t,this.t);while(i<m){c+=this[i]-a[i];r[i++]=c&this.DM;c>>=this.DB}if(a.t<this.t){c-=a.s;while(i<this.t){c+=this[i];r[i++]=c&this.DM;c>>=this.DB}c+=this.s}else{c+=this.s;while(i<a.t){c-=a[i];r[i++]=c&this.DM;c>>=this.DB}c-=a.s}r.s=c<0?-1:0;if(c<-1)r[i++]=this.DV+c;else if(c>0)r[i++]=c;r.t=i;r.clamp()}
function e2ee_aR(a,r){var x=this.abs(),y=a.abs();var i=x.t;r.t=i+y.t;while(--i>=0)r[i]=0;for(i=0;i<y.t;++i)r[i+x.t]=x.am(0,y[i],r,i,0,x.t);r.s=0;r.clamp();if(this.s!=a.s)e2ee_i.ZERO.subTo(r,r)}function e2ee_bl(r){var x=this.abs();var i=r.t=2*x.t;while(--i>=0)r[i]=0;for(i=0;i<x.t-1;++i){var c=x.am(i,x[i],r,2*i,0,1);if((r[i+x.t]+=x.am(i+1,2*x[i],r,2*i+1,c,x.t-i-1))>=x.DV){r[i+x.t]-=x.DV;r[i+x.t+1]=1}}if(r.t>0)r[r.t-1]+=x.am(i,x[i],r,2*i,0,1);r.s=0;r.clamp()}
function e2ee_bd(m,q,r){var pm=m.abs();if(pm.t<=0)return;var pt=this.abs();if(pt.t<pm.t){if(q!=null)q.e2ee_N(0);if(r!=null)this.e2ee_J(r);return}if(r==null)r=nbi();var y=nbi(),ts=this.s,ms=m.s;var nsh=this.DB-nbits(pm[pm.t-1]);if(nsh>0){pm.e2ee_X(nsh,y);pt.e2ee_X(nsh,r)}else{pm.e2ee_J(y);pt.e2ee_J(r)}var ys=y.t;var y0=y[ys-1];if(y0==0)return;var yt=y0*(1<<this.F1)+(ys>1?y[ys-2]>>this.F2:0);var d1=this.FV/yt,d2=(1<<this.F1)/yt,e=1<<this.F2;var i=r.t,j=i-ys,t=q==null?nbi():q;y.e2ee_s(j,t);if(r.e2ee_b(t)>=
    0){r[r.t++]=1;r.subTo(t,r)}e2ee_i.ONE.e2ee_s(ys,t);t.subTo(y,y);while(y.t<ys)y[y.t++]=0;while(--j>=0){var qd=r[--i]==y0?this.DM:Math.floor(r[i]*d1+(r[i-1]+e)*d2);if((r[i]+=y.am(0,qd,r,j,0,ys))<qd){y.e2ee_s(j,t);r.subTo(t,r);while(r[i]<--qd)r.subTo(t,r)}}if(q!=null){r.e2ee_u(ys,q);if(ts!=ms)e2ee_i.ZERO.subTo(q,q)}r.t=ys;r.clamp();if(nsh>0)r.e2ee_aX(nsh,r);if(ts<0)e2ee_i.ZERO.subTo(r,r)}
function bnMod(a){var r=nbi();this.abs().e2ee_C(a,null,r);if(this.s<0&&r.e2ee_b(e2ee_i.ZERO)>0)a.subTo(r,r);return r}function e2ee_v(m){this.m=m}function e2ee_bq(x){if(x.s<0||x.e2ee_b(this.m)>=0)return x.mod(this.m);else return x}function e2ee_bH(x){return x}function e2ee_bt(x){x.e2ee_C(this.m,null,x)}function cMulTo(x,y,r){x.e2ee_p(y,r);this.reduce(r)}function cSqrTo(x,r){x.e2ee_e(r);this.reduce(r)}e2ee_v.prototype.convert=e2ee_bq;e2ee_v.prototype.revert=e2ee_bH;e2ee_v.prototype.reduce=e2ee_bt;
e2ee_v.prototype.mulTo=cMulTo;e2ee_v.prototype.sqrTo=cSqrTo;function e2ee_aD(){if(this.t<1)return 0;var x=this[0];if((x&1)==0)return 0;var y=x&3;y=y*(2-(x&15)*y)&15;y=y*(2-(x&255)*y)&255;y=y*(2-((x&65535)*y&65535))&65535;y=y*(2-x*y%this.DV)%this.DV;return y>0?this.DV-y:-y}function e2ee_U(m){this.m=m;this.mp=m.e2ee_aa();this.mpl=this.mp&32767;this.mph=this.mp>>15;this.um=(1<<m.DB-15)-1;this.mt2=2*m.t}
function e2ee_bb(x){var r=nbi();x.abs().e2ee_s(this.m.t,r);r.e2ee_C(this.m,null,r);if(x.s<0&&r.e2ee_b(e2ee_i.ZERO)>0)this.m.subTo(r,r);return r}function e2ee_bO(x){var r=nbi();x.e2ee_J(r);this.reduce(r);return r}
function e2ee_bh(x){while(x.t<=this.mt2)x[x.t++]=0;for(var i=0;i<this.m.t;++i){var j=x[i]&32767;var u0=j*this.mpl+((j*this.mph+(x[i]>>15)*this.mpl&this.um)<<15)&x.DM;j=i+this.m.t;x[j]+=this.m.am(0,u0,x,i,0,this.m.t);while(x[j]>=x.DV){x[j]-=x.DV;x[++j]++}}x.clamp();x.e2ee_u(this.m.t,x);if(x.e2ee_b(this.m)>=0)x.subTo(this.m,x)}function e2ee_aL(x,r){x.e2ee_e(r);this.reduce(r)}function e2ee_ap(x,y,r){x.e2ee_p(y,r);this.reduce(r)}e2ee_U.prototype.convert=e2ee_bb;e2ee_U.prototype.revert=e2ee_bO;
e2ee_U.prototype.reduce=e2ee_bh;e2ee_U.prototype.mulTo=e2ee_ap;e2ee_U.prototype.sqrTo=e2ee_aL;function e2ee_bG(){return(this.t>0?this[0]&1:this.s)==0}function bnpExp(e,z){if(e>4294967295||e<1)return e2ee_i.ONE;var r=nbi(),r2=nbi(),g=z.convert(this),i=nbits(e)-1;g.e2ee_J(r);while(--i>=0){z.sqrTo(r,r2);if((e&1<<i)>0)z.mulTo(r2,g,r);else{var t=r;r=r2;r2=t}}return z.revert(r)}function e2ee_bn(e,m){var z;if(e<256||m.isEven())z=new e2ee_v(m);else z=new e2ee_U(m);return this.exp(e,z)}
e2ee_i.prototype.e2ee_J=e2ee_ai;e2ee_i.prototype.e2ee_N=e2ee_aC;e2ee_i.prototype.e2ee_K=e2ee_ad;e2ee_i.prototype.clamp=e2ee_aK;e2ee_i.prototype.e2ee_s=e2ee_av;e2ee_i.prototype.e2ee_u=e2ee_bN;e2ee_i.prototype.e2ee_X=e2ee_aO;e2ee_i.prototype.e2ee_aX=e2ee_aV;e2ee_i.prototype.subTo=e2ee_bR;e2ee_i.prototype.e2ee_p=e2ee_aR;e2ee_i.prototype.e2ee_e=e2ee_bl;e2ee_i.prototype.e2ee_C=e2ee_bd;e2ee_i.prototype.e2ee_aa=e2ee_aD;e2ee_i.prototype.isEven=e2ee_bG;e2ee_i.prototype.exp=bnpExp;
e2ee_i.prototype.toString=e2ee_aI;e2ee_i.prototype.e2ee_P=e2ee_bk;e2ee_i.prototype.abs=bnAbs;e2ee_i.prototype.e2ee_b=e2ee_aq;e2ee_i.prototype.e2ee_a=e2ee_bc;e2ee_i.prototype.mod=bnMod;e2ee_i.prototype.e2ee_bC=e2ee_bn;e2ee_i.ZERO=nbv(0);e2ee_i.ONE=nbv(1);function e2ee_m(){this.i=0;this.j=0;this.S=new Array}function e2ee_ac(key){var i,j,t;for(i=0;i<256;++i)this.S[i]=i;j=0;for(i=0;i<256;++i){j=j+this.S[i]+key[i%key.length]&255;t=this.S[i];this.S[i]=this.S[j];this.S[j]=t}this.i=0;this.j=0}
function e2ee_aA(){var t;this.i=this.i+1&255;this.j=this.j+this.S[this.i]&255;t=this.S[this.i];this.S[this.i]=this.S[this.j];this.S[this.j]=t;return this.S[t+this.S[this.i]&255]}e2ee_m.prototype.init=e2ee_ac;e2ee_m.prototype.next=e2ee_aA;function e2ee_ay(){return new e2ee_m}var e2ee_x=256;var e2ee_W;var e2ee_g;var e2ee_F;function e2ee_af(x){e2ee_g[e2ee_F++]^=x&255;e2ee_g[e2ee_F++]^=x>>8&255;e2ee_g[e2ee_F++]^=x>>16&255;e2ee_g[e2ee_F++]^=x>>24&255;if(e2ee_F>=e2ee_x)e2ee_F-=e2ee_x}
function rng_seed_time(){e2ee_af((new Date).getTime())}if(e2ee_g==null){e2ee_g=new Array;e2ee_F=0;var t;if(navigator.appName=="Netscape"&&(navigator.appVersion<"5"&&window.crypto)){var z=window.crypto.random(32);for(t=0;t<z.length;++t)e2ee_g[e2ee_F++]=z.charCodeAt(t)&255}while(e2ee_F<e2ee_x){t=Math.floor(65536*Math.random());e2ee_g[e2ee_F++]=t>>>8;e2ee_g[e2ee_F++]=t&255}e2ee_F=0;rng_seed_time()}
function e2ee_Z(){if(e2ee_W==null){rng_seed_time();e2ee_W=e2ee_ay();e2ee_W.init(e2ee_g);for(e2ee_F=0;e2ee_F<e2ee_g.length;++e2ee_F)e2ee_g[e2ee_F]=0;e2ee_F=0}return e2ee_W.next()}function e2ee_bu(ba){var i;for(i=0;i<ba.length;++i)ba[i]=e2ee_Z()}function e2ee_R(){}e2ee_R.prototype.e2ee_bx=e2ee_bu;e2ee_R.prototype.e2ee_aY=e2ee_Z;var e2ee_bV=20;
function e2ee_bE(in1,inOff){this.X[this.xOff++]=(in1[inOff]&255)<<24|(in1[inOff+1]&255)<<16|(in1[inOff+2]&255)<<8|in1[inOff+3]&255;if(this.xOff==16)this.e2ee_n()}function e2ee_f(x){var rs=x&255;return rs}function e2ee_A(word,out,e2ee_S){out[e2ee_S]=e2ee_f(word>>>24);out[e2ee_S+1]=e2ee_f(word>>>16);out[e2ee_S+2]=e2ee_f(word>>>8);out[e2ee_S+3]=e2ee_f(word)}function e2ee_B(e2ee_a){if(this.xOff>14)this.e2ee_n();this.X[14]=0;this.X[15]=e2ee_a&4294967295}
function e2ee_h(e2ee_S){this.finish();var out=new Array(20);this.e2ee_A(this.H1,out,e2ee_S);this.e2ee_A(this.H2,out,e2ee_S+4);this.e2ee_A(this.H3,out,e2ee_S+8);this.e2ee_A(this.H4,out,e2ee_S+12);this.e2ee_A(this.H5,out,e2ee_S+16);this.reset();return out}function reset(){var i=0;this.e2ee_l=0;this.e2ee_I=0;for(i=0;i<this.xBuf.length;i++)this.xBuf[i]=0;this.H1=1732584193;this.H2=4023233417;this.H3=2562383102;this.H4=271733878;this.H5=3285377520;this.xOff=0;for(i=0;i!=this.X.length;i++)this.X[i]=0}
function f(u,v,w){return u&v|~u&w}function h(u,v,w){return u^v^w}function g(u,v,w){return u&v|u&w|v&w}function e2ee_T(x,n){return x<<n|x>>>32-n}
function e2ee_n(){var i;var j;var Y1=1518500249;var Y2=1859775393;var Y3=-1894007588;var Y4=-899497514;for(i=16;i<=79;i++)this.X[i]=e2ee_T(this.X[i-3]^this.X[i-8]^this.X[i-14]^this.X[i-16],1);var A=this.H1;var B=this.H2;var C=this.H3;var D=this.H4;var E=this.H5;for(j=0;j<=19;j++){var t=e2ee_T(A,5)+f(B,C,D)+E+this.X[j]+Y1;t=t&4294967295;E=D;D=C;C=e2ee_T(B,30);B=A;A=t}for(j=20;j<=39;j++){var t=e2ee_T(A,5)+h(B,C,D)+E+this.X[j]+Y2;t=t&4294967295;E=D;D=C;C=e2ee_T(B,30);B=A;A=t}for(j=40;j<=59;j++){var t=
    e2ee_T(A,5)+g(B,C,D)+E+this.X[j]+Y3;t=t&4294967295;E=D;D=C;C=e2ee_T(B,30);B=A;A=t}for(j=60;j<=79;j++){var t=e2ee_T(A,5)+h(B,C,D)+E+this.X[j]+Y4;t=t&4294967295;E=D;D=C;C=e2ee_T(B,30);B=A;A=t}this.H1=this.H1+A&4294967295;this.H2=this.H2+B&4294967295;this.H3=this.H3+C&4294967295;this.H4=this.H4+D&4294967295;this.H5=this.H5+E&4294967295;this.xOff=0;for(i=0;i!=this.X.length;i++)this.X[i]=0}
function update(in1){this.xBuf[this.e2ee_I++]=in1;if(this.e2ee_I==this.xBuf.length){this.e2ee_o(this.xBuf,0);this.e2ee_I=0}this.e2ee_l++}function e2ee_Q(in1,inOff,len){while(this.e2ee_I!=0&&len>0){update(in1[inOff]);inOff++;len--}while(len>this.xBuf.length){this.e2ee_o(in1,inOff);inOff+=this.e2ee_aH;len-=this.xBuf.length;this.e2ee_l+=this.xBuf.length}while(len>0){this.update(in1[inOff]);inOff++;len--}}
function finish(){var e2ee_a=this.e2ee_l<<3;this.update(-128);while(this.e2ee_I!=0)this.update(0);this.e2ee_B(e2ee_a);this.e2ee_n()}function e2ee_c(){this.X=new Array(80);this.xBuf=new Array(4);this.e2ee_I=0;this.e2ee_aH=4;this.e2ee_l=0;this.xOff=0;var i=0;for(i=0;i<this.xBuf.length;i++)this.xBuf[i]=0;this.H1=1732584193;this.H2=-271733879;this.H3=-1732584194;this.H4=271733878;this.H5=-1009589776;this.xOff=0;for(i=0;i!=this.X.length;i++)this.X[i]=0}e2ee_c.prototype.e2ee_Q=e2ee_Q;
e2ee_c.prototype.e2ee_h=e2ee_h;e2ee_c.prototype.e2ee_o=e2ee_bE;e2ee_c.prototype.update=update;e2ee_c.prototype.reset=reset;e2ee_c.prototype.finish=finish;e2ee_c.prototype.e2ee_B=e2ee_B;e2ee_c.prototype.e2ee_n=e2ee_n;e2ee_c.prototype.e2ee_A=e2ee_A;
var e2ee_H=[-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,0,1,2,3,4,5,6,7,8,9,-1,-1,-1,-1,-1,-1,-1,10,11,12,13,14,15,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,10,11,12,13,14,15,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,
    -1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1];function e2ee_V(e2ee_bf,length){var i;var e2ee_as=length;var e2ee_G=e2ee_as/2;var e2ee_D=new Array(e2ee_G);for(i=0;i<e2ee_G;i++)e2ee_D[i]=(e2ee_H[e2ee_bf.charCodeAt(i*2)]<<4|e2ee_H[e2ee_bf.charCodeAt(i*2+1)])&255;return e2ee_D}
var e2ee_Y=20;function e2ee_aZ(str,r){return new e2ee_i(str,r)}function e2ee_bK(s,n){var ret="";var i=0;while(i+n<s.length){ret+=s.substring(i,i+n)+"\n";i+=n}return ret+s.substring(i,s.length)}function e2ee_aB(b){if(b<16)return"0"+b.toString(16);else return b.toString(16)}
function e2ee_d(e2ee_r,e2ee_aj){var e2ee_w=new e2ee_c;var e2ee_bL=new Array(20);var i;var e2ee_k=new Array(e2ee_r.length);for(i=0;i<e2ee_r.length;i++)e2ee_k[i]=e2ee_r.charCodeAt(i);e2ee_w.e2ee_Q(e2ee_k,0,e2ee_aj);var result=e2ee_w.e2ee_h(0);return result}
function e2ee_y(s,n){var slen=s.length;var tl=slen;var ba=new Array;var i=tl-1;while(i>=0&&n>0){var c;c=s[i--];ba[--n]=c}ba[--n]=0;var rng=new e2ee_R;var x=new Array;while(n>2){x[0]=0;while(x[0]==0)rng.e2ee_bx(x);ba[--n]=x[0]}ba[--n]=2;ba[--n]=0;return new e2ee_i(ba)}function RSAEngine(){this.n=null;this.e=0;this.d=null;this.p=null;this.q=null;this.dmp1=null;this.dmq1=null;this.coeff=null;this.srd=null;this.sid=null}
function e2ee_bF(e2ee_z,SID,SRD){if(e2ee_z!=null&&e2ee_z.length>0){var e2ee_E=e2ee_z.split(":");if(e2ee_E.length!=2)alert("Invalide public key content");var N=e2ee_E[0];var E=e2ee_E[1];this.n=e2ee_aZ(N,16);this.e=parseInt(E,16);this.srd=SRD;this.sid=SID}else alert("Invalid RSA public key")}function e2ee_ax(x){return x.e2ee_bC(this.e,this.n)}
function e2ee_au(text){var e2ee_aN=e2ee_d(text,text.length);var e2ee_O=e2ee_q(e2ee_Y);var e2ee_j=e2ee_V(this.srd,this.srd.length);var pack=pack1(e2ee_j,e2ee_aN,e2ee_O);var m=this.e2ee_y(pack,this.n.e2ee_a()+7>>3);if(m==null)return null;var c=this.e2ee_t(m);if(c==null)return null;var h=c.toString(16);var extra="";if((h.length&1)==0)return extra+h;else return extra+"0"+h}
function e2ee_ag(text1,text2){var e2ee_by=e2ee_d(text1,text1.length);var e2ee_at=e2ee_d(text2,text2.length);var e2ee_O=e2ee_q(e2ee_Y);var e2ee_j=e2ee_V(this.srd,this.srd.length);var pack=pack2(e2ee_j,e2ee_by,e2ee_at,e2ee_O);var m=this.e2ee_y(pack,this.n.e2ee_a()+7>>3);if(m==null)return null;var c=this.e2ee_t(m);if(c==null)return null;var h=c.toString(16);var extra="";if((h.length&1)==0)return extra+h;else return extra+"0"+h}
function pack1(var1,var2,var3){var pack=new Array(var1.length+var2.length+var3.length);var i;for(i=0;i<var1.length;i++)pack[i]=var1[i];for(i=0;i<var2.length;i++)pack[i+var1.length]=var2[i];for(i=0;i<var3.length;i++)pack[i+var1.length+var2.length]=var3[i];return pack}
function pack2(var1,var2,var3,var4){var pack=new Array(var1.length+var2.length+var3.length+var4.length);var i;for(i=0;i<var1.length;i++)pack[i]=var1[i];for(i=0;i<var2.length;i++)pack[i+var1.length]=var2[i];for(i=0;i<var3.length;i++)pack[i+var1.length+var2.length]=var3[i];for(i=0;i<var4.length;i++)pack[i+var1.length+var2.length+var3.length]=var4[i];return pack}
function e2ee_q(size){var plainText=new Array(size);var i;var rng=new e2ee_R;for(i=0;i<size;i++)plainText[i]=rng.e2ee_aY();return plainText}RSAEngine.prototype.e2ee_t=e2ee_ax;RSAEngine.prototype.init=e2ee_bF;RSAEngine.prototype.e2ee_y=e2ee_y;RSAEngine.prototype.encryptPIN1=e2ee_au;RSAEngine.prototype.encryptPIN2=e2ee_ag;var e2ee_M="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";var b64pad="\x3d";
function e2ee_aJ(h){var i;var c;var ret="";for(i=0;i+3<=h.length;i+=3){c=parseInt(h.substring(i,i+3),16);ret+=e2ee_M.charAt(c>>6)+e2ee_M.charAt(c&63)}if(i+1==h.length){c=parseInt(h.substring(i,i+1),16);ret+=e2ee_M.charAt(c<<2)}else if(i+2==h.length){c=parseInt(h.substring(i,i+2),16);ret+=e2ee_M.charAt(c>>2)+e2ee_M.charAt((c&3)<<4)}while((ret.length&3)>0)ret+=b64pad;return ret}
function e2ee_aQ(s){var ret="";var i;var k=0;var slop;for(i=0;i<s.length;++i){if(s.charAt(i)==b64pad)break;v=e2ee_M.indexOf(s.charAt(i));if(v<0)continue;if(k==0){ret+=e2ee_L(v>>2);slop=v&3;k=1}else if(k==1){ret+=e2ee_L(slop<<2|v>>4);slop=v&15;k=2}else if(k==2){ret+=e2ee_L(slop);ret+=e2ee_L(v>>2);slop=v&3;k=3}else{ret+=e2ee_L(slop<<2|v>>4);ret+=e2ee_L(v&15);k=0}}if(k==1)ret+=e2ee_L(slop<<2);return ret}
function e2ee_bg(s){var h=e2ee_aQ(s);var i;var a=new Array;for(i=0;2*i<h.length;++i)a[i]=parseInt(h.substring(2*i,2*i+2),16);return a};
